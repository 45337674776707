import React, { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';

import { Grid } from '@material-ui/core';
import Layout from '../components/layout';
import Navigator from '../components/navigator';
import AuthenticatedPage from '../components/authenticated-page';
import { getToken, checkJWTExpired } from '../utils/utils';

import {
  TransferList,
  DetailDialog,
  FilterDialog,
  FilterDisplayer,
} from '../components/market-finder';

import { GET_TRANSFER_QUERY, GET_TRANSFER_BY_ID_QUERY, GET_CURRENCIES } from '../crud';

const processTransferDetail = (data) => {
  if (!data || !data.transferById) return {};
  const detail = data.transferById;
  try {
    detail.transfer_to_originator_res_message = JSON.parse(
      detail.transfer_to_originator_res_message,
    );
  } catch (error) {
    // do nothing when data is not JSON
  }
  try {
    detail.transfer_to_beneficiary_res_message = JSON.parse(
      detail.transfer_to_beneficiary_res_message,
    );
  } catch (error) {
    // do nothing when data is not JSON
  }
  if (detail.transfer_to_originator_validate_addr_res_message) {
    try {
      detail.transfer_to_originator_validate_addr_res_message = JSON.parse(
        detail.transfer_to_originator_validate_addr_res_message,
      );
    } catch (error) {
      // do nothing when data is not JSON
    }
  }
  if (detail.validate_addr_error_message) {
    try {
      detail.validate_addr_error_message = JSON.parse(detail.validate_addr_error_message);
    } catch (error) {
      // do nothing when data is not JSON
    }
  }
  return detail;
};

function MarketFinder() {
  const token = getToken();

  const [getTransfer, getTransferResult] = useLazyQuery(GET_TRANSFER_QUERY);
  const [getTransferById, { data: transfer }] = useLazyQuery(GET_TRANSFER_BY_ID_QUERY);

  const [filterState, setFilterState] = useState({
    origVaspCode: '',
    beneVaspCode: '',
    origAddrs: new Set(),
    beneAddrs: new Set(),
    selectedFromDate: null,
    selectedToDate: null,
    currency_id: '',
    status: new Set(),
    transferIDs: new Set(),
  });

  // const [origVaspCode, setOrigVaspCode] = useState('');
  // const [beneVaspCode, setBeneVaspCode] = useState('');

  // const [origAddrs, setOrigAddrs] = useState(new Set());
  // const [beneAddrs, setBeneAddrs] = useState(new Set());

  // // interval
  // const [selectedFromDate, setFromDate] = useState(null);
  // const [selectedToDate, setToDate] = useState(null);

  // const [currency, setCurrency] = useState('');

  // const [status, setStatus] = useState({ ...statusDefaultState });

  // control filter panel
  const [filterPanelOpen, setFilterPanelOpen] = useState(false);
  // control detail panel
  const [detailPanelOpen, setDetailPanelOpen] = useState(false);

  const handleGetTransfer = (e, isButton) => {
    e.preventDefault();
    // prevent query when last query haven't returned
    if (getTransferResult.loading) return;
    // if there is previous query data, and no last key(no more data!)
    // and not trigger by button(form) (i.e.: trigger by `scrollToBottom`)
    // don't run query
    if (getTransferResult.data && !getTransferResult.data.transfer.nextOffset && !isButton) return;
    const fromStr = filterState.selectedFromDate ? filterState.selectedFromDate.toISOString() : '';
    const toStr = filterState.selectedToDate ? filterState.selectedToDate.toISOString() : '';
    getTransfer({
      variables: {
        ocode: filterState.origVaspCode === '' ? undefined : filterState.origVaspCode,
        bcode: filterState.beneVaspCode === '' ? undefined : filterState.beneVaspCode,
        oaddrs: [...filterState.origAddrs.keys()],
        baddrs: [...filterState.beneAddrs.keys()],
        interval: `${fromStr},${toStr}`,
        currency_id: filterState.currency_id === '' ? undefined : filterState.currency_id,
        status: [...filterState.status.keys()],
        transfer_ids: [...filterState.transferIDs.keys()],
        // isButton: prevent pageination from button, should only trigger by "scrollToBottom"
        offset:
          !isButton && getTransferResult.data && getTransferResult.data.transfer.nextOffset
            ? getTransferResult.data.transfer.nextOffset
            : undefined,
      },
      context: {
        headers: {
          authorization: token,
        },
      },
    });
  };

  const [getCurrencies, getCurrenciesResult] = useLazyQuery(GET_CURRENCIES, {
    variables: {},
    context: {
      headers: {
        authorization: token,
      },
    },
    onCompleted: () => {
      handleGetTransfer({ preventDefault: () => {} }, true);
    },
    fetchPolicy: 'no-cache',
  });

  const handleSubmit = (e) => {
    setFilterPanelOpen(false);
    // setTransferDataList([]);
    handleGetTransfer(e, true);
  };

  const handleDetailClicked = (id) => {
    getTransferById({
      variables: { id },
      context: {
        headers: {
          authorization: token,
        },
      },
    });
    setDetailPanelOpen(true);
  };

  useEffect(() => {
    if (token && !checkJWTExpired(token)) {
      getCurrencies();
    }
  }, []);

  return (
    <Layout>
      <AuthenticatedPage>
        <Navigator
          breadcrumbTexts={['Home', 'Market Finder']}
          breadcrumbLinks={['/', '/market-finder']}
        />

        <Grid container style={{ flexDirection: 'column' }}>
          <TransferList
            getCurrenciesResult={getCurrenciesResult}
            dataResult={getTransferResult}
            onScrollToBottom={handleGetTransfer}
            onDetailClicked={handleDetailClicked}
          />
        </Grid>

        <FilterDisplayer
          onOpenFilterClicked={() => setFilterPanelOpen(true)}
          filterState={filterState}
          getCurrenciesResult={getCurrenciesResult}
        />

        <FilterDialog
          open={filterPanelOpen}
          setOpen={setFilterPanelOpen}
          onSubmit={handleSubmit}
          filterState={filterState}
          setFilterState={setFilterState}
          getCurrenciesResult={getCurrenciesResult}
        />

        <DetailDialog
          open={detailPanelOpen}
          onClose={() => setDetailPanelOpen(false)}
          data={processTransferDetail(transfer)}
        />
      </AuthenticatedPage>
    </Layout>
  );
}

export default MarketFinder;
