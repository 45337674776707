import React from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';

import {
  Grid,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  InputLabel,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import moment from 'moment';

import { TextFieldWithClear, DateTimeWidgetWithClear, TextFieldWithMultiValue } from '../common';

import { TransferStatus } from '../../constants';
import { parseCurrencies } from '../../utils/utils';
import { Currency } from '../types';

function FilterDialog({
  open,
  setOpen,
  onSubmit,
  filterState,
  setFilterState,
  getCurrenciesResult,
}) {
  const currencyOptions = parseCurrencies(getCurrenciesResult).map((currency) => ({
    groupTitle: currency.is_token ? 'token' : '',
    ...currency,
  }));

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="form-dialog-title"
      maxWidth="xl"
    >
      <DialogTitle id="form-dialog-title">Filter</DialogTitle>
      <DialogContent>
        <form method="post" onSubmit={onSubmit}>
          <Grid container spacing={4}>
            <Grid container item direction="column" spacing={1} md={6}>
              <Grid item key="fromDate">
                <DateTimeWidgetWithClear
                  text="From Date"
                  setState={(s) =>
                    setFilterState(update(filterState, { selectedFromDate: { $set: s } }))
                  }
                  fromState={filterState.selectedFromDate}
                />
              </Grid>
              <Grid item key="toDate">
                <DateTimeWidgetWithClear
                  text="To Date"
                  setState={(s) =>
                    setFilterState(update(filterState, { selectedToDate: { $set: s } }))
                  }
                  fromState={filterState.selectedToDate}
                />
              </Grid>
              <Grid item key="origVaspCode">
                <TextFieldWithClear
                  label="Originator Vasp Code"
                  setState={(s) =>
                    setFilterState(update(filterState, { origVaspCode: { $set: s } }))
                  }
                  fromState={filterState.origVaspCode}
                />
              </Grid>
              <Grid item key="beneVaspCode">
                <TextFieldWithClear
                  label="Beneficiary Vasp Code"
                  setState={(s) =>
                    setFilterState(update(filterState, { beneVaspCode: { $set: s } }))
                  }
                  fromState={filterState.beneVaspCode}
                />
              </Grid>
              <Grid item key="currency_id">
                <Autocomplete
                  autoHighlight
                  options={currencyOptions}
                  groupBy={(option) => option.groupTitle}
                  getOptionLabel={(option) => {
                    if (!option) {
                      return '';
                    }
                    return `${option.currency_name} (${option.currency_symbol})`;
                  }}
                  style={{ width: '20em' }}
                  onChange={(__, v) => {
                    setFilterState(
                      update(filterState, {
                        currency_id: { $set: v ? v.currency_id : '' },
                      }),
                    );
                  }}
                  value={currencyOptions.find(
                    (currency) => currency.currency_id === filterState.currency_id,
                  )}
                  renderInput={(params) => <TextField {...params} label="Currency" fullWidth />}
                />
              </Grid>
              <Grid item style={{ marginTop: '1em' }}>
                <InputLabel id="statusInputLabel">Status</InputLabel>
              </Grid>
              <Grid container item direction="column">
                {TransferStatus.map((s) => (
                  <Grid item key={`grid${s}`}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={filterState.status.has(s)}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setFilterState(update(filterState, { status: { $add: [s] } }));
                            } else {
                              setFilterState(
                                update(filterState, {
                                  status: { $remove: [s] },
                                }),
                              );
                            }
                          }}
                          value={s}
                        />
                      }
                      label={s}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid container item direction="column" md={6} spacing={1}>
              <Grid item key="origAddr">
                <TextFieldWithMultiValue
                  label="Originator Address"
                  setState={(s) => setFilterState(update(filterState, { origAddrs: { $set: s } }))}
                  fromState={filterState.origAddrs}
                />
              </Grid>
              <Grid item key="beneAddr">
                <TextFieldWithMultiValue
                  label="Beneficiary Address"
                  setState={(s) => setFilterState(update(filterState, { beneAddrs: { $set: s } }))}
                  fromState={filterState.beneAddrs}
                />
              </Grid>
              <Grid item key="transferID">
                <TextFieldWithMultiValue
                  label="Transfer ID"
                  setState={(s) =>
                    setFilterState(update(filterState, { transferIDs: { $set: s } }))
                  }
                  fromState={filterState.transferIDs}
                />
              </Grid>
            </Grid>
          </Grid>
          <Button type="submit" id="invisibleSubmit" onClick={onSubmit} style={{ display: 'none' }}>
            Submit
          </Button>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)}>Cancel</Button>
        <Button onClick={onSubmit}>Apply</Button>
      </DialogActions>
    </Dialog>
  );
}

FilterDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  filterState: PropTypes.shape({
    origVaspCode: PropTypes.string,
    beneVaspCode: PropTypes.string,
    selectedFromDate: PropTypes.instanceOf(moment),
    selectedToDate: PropTypes.instanceOf(moment),
    currency_id: PropTypes.string,
    status: PropTypes.instanceOf(Set),
    origAddrs: PropTypes.instanceOf(Set),
    beneAddrs: PropTypes.instanceOf(Set),
    transferIDs: PropTypes.instanceOf(Set),
  }).isRequired,
  setFilterState: PropTypes.func.isRequired,
  getCurrenciesResult: PropTypes.shape({
    loading: PropTypes.bool,
    data: PropTypes.shape({
      getCurrencies: PropTypes.arrayOf(Currency),
    }),
  }).isRequired,
};

export default FilterDialog;
