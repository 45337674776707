import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

export function LoadingRow({ style, index }) {
  return (
    <Grid container style={style}>
      <Grid
        item
        key={`${index}dt`}
        style={{
          flexBasis: '18%',
          textAlign: 'center',
          padding: '0 1rem 0 1rem',
        }}
      >
        <Skeleton variant="text" width="100%" height="100%" />
      </Grid>
      <Grid
        item
        key={`${index}from`}
        style={{
          flexBasis: '18%',
          textAlign: 'center',
          padding: '0 0.5rem 0 0.5rem',
        }}
      >
        <Skeleton variant="text" />
      </Grid>
      <Grid item key={`${index}arrow`} style={{ flexBasis: '3%', textAlign: 'center' }} />
      <Grid
        item
        key={`${index}to`}
        style={{
          flexBasis: '18%',
          textAlign: 'center',
          padding: '0 0.5rem 0 0.5rem',
        }}
      >
        <Skeleton variant="text" />
      </Grid>
      <Grid
        item
        key={`${index}coin`}
        style={{ flexBasis: '21%', textAlign: 'left', padding: '0 1rem 0 0' }}
      >
        <Skeleton variant="text" />
      </Grid>
      <Grid
        item
        key={`${index}sta`}
        style={{ flexBasis: '14%', textAlign: 'left', padding: '0 1rem 0 0' }}
      >
        <Skeleton variant="text" />
      </Grid>
    </Grid>
  );
}
LoadingRow.propTypes = {
  style: PropTypes.objectOf(PropTypes.any).isRequired,
  index: PropTypes.number.isRequired,
};
