import React from 'react';

import { Breadcrumbs, Link, Drawer, List, ListItem, Typography } from '@material-ui/core';
import { genNavigatePath } from '../utils/utils';
import { RoleId } from '../constants';
import $ from '../config';
import RequireRole from './require-role';

const renderList = () => (
  <List>
    <ListItem button key="home" component="a" href={genNavigatePath('/profile')}>
      Home
    </ListItem>
    <RequireRole roleIds={[RoleId.ACCOUNT_MANAGER, RoleId.ADMIN]}>
      <ListItem
        button
        key="VASPLists"
        component="a"
        href={genNavigatePath('/vasp-list')}
        id="vasp_list"
      >
        VASP Lists
      </ListItem>
    </RequireRole>
    <ListItem
      button
      key="MarketFinder"
      component="a"
      href={genNavigatePath('/market-finder')}
      id="market-finder"
    >
      Market Finder
    </ListItem>
    <RequireRole roleIds={[RoleId.ACCOUNT_MANAGER, RoleId.ADMIN]}>
      <>
        <ListItem
          button
          key="ApproveVASP"
          component="a"
          href={genNavigatePath('/approve-vasp-list')}
          id="approve_vasp"
        >
          Approve VASP
        </ListItem>

        <ListItem
          button
          key="VASPUpdateRequest"
          component="a"
          href={genNavigatePath('/vasp-update-request-list')}
          id="vasp_update_request"
        >
          VASP Update Request
        </ListItem>

        <ListItem
          button
          key="VASPRegulation"
          component="a"
          href={genNavigatePath('/vasp-regulation-list')}
          id="vasp_regulation"
        >
          VASP Regulation
        </ListItem>

        <ListItem
          button
          key="DownloadReport"
          component="a"
          href={genNavigatePath('/download-report')}
          id="download_report"
        >
          Download Report
        </ListItem>

        {$.GATSBY_ENV !== 'prod' ? (
          <ListItem
            button
            key="Monitoring"
            component="a"
            href={genNavigatePath('/monitoring-list')}
            id="monitoring"
          >
            Monitoring
          </ListItem>
        ) : null}

        <ListItem
          button
          key="BundleService"
          component="a"
          href={genNavigatePath('/bundle-service-vasp-list')}
          id="bundle_service"
        >
          Bundle&nbsp;Service
        </ListItem>
      </>
    </RequireRole>
  </List>
);

// if link is empty , render Typography
const genBreadcrumbs = (breadcrumbTexts, breadcrumbLinks) =>
  breadcrumbTexts.map((val, i) => {
    if (!breadcrumbLinks[i]) {
      return (
        <Typography color="breadcrumbTexts" key={`l${val}`}>
          {val}
        </Typography>
      );
    }
    return (
      <Link color="inherit" href={genNavigatePath(breadcrumbLinks[i])} key={`l${val}`}>
        {val}
      </Link>
    );
  });

function Navigator(prop) {
  return (
    <>
      <Breadcrumbs aria-label="breadcrumb">
        {genBreadcrumbs(prop.breadcrumbTexts, prop.breadcrumbLinks)}
      </Breadcrumbs>
      <Drawer
        variant="permanent"
        anchor="left"
        PaperProps={{
          style: {
            width: '12%',
            maxWidth: '200px',
          },
        }}
      >
        {renderList()}
      </Drawer>
    </>
  );
}

export default Navigator;
