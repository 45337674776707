import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { Currency } from '../types';

export function NormalDataRow({ style, data }) {
  const currency = data.currency
    ? `${data.currency.currency_name} (${data.currency.currency_symbol})`
    : 'Unavailable';
  return (
    <Grid container style={style}>
      <Grid
        item
        key={`${data.id}dt`}
        style={{
          flexBasis: '18%',
          textAlign: 'center',
          textDecoration: 'underline',
          cursor: 'pointer',
        }}
        onClick={() => data.detailHandler(data.id)}
      >
        {data.datetime}
      </Grid>
      <Grid
        item
        key={`${data.id}from`}
        style={{ flexBasis: '18%', textAlign: 'center', wordBreak: 'break-all' }}
      >
        {data.id}
      </Grid>
      <Grid item key={`${data.id}from`} style={{ flexBasis: '18%', textAlign: 'center' }}>
        {data.from}
      </Grid>
      <Grid item key={`${data.id}arrow`} style={{ flexBasis: '3%', textAlign: 'center' }}>
        <ArrowForwardIcon />
      </Grid>
      <Grid item key={`${data.id}to`} style={{ flexBasis: '18%', textAlign: 'center' }}>
        {data.to}
      </Grid>
      <Grid item key={`${data.id}currency`} style={{ flexBasis: '10%', textAlign: 'left' }}>
        {`${currency}`}
      </Grid>
      <Grid item key={`${data.id}sta`} style={{ flexBasis: '14%', textAlign: 'left' }}>
        {data.status}
      </Grid>
    </Grid>
  );
}

NormalDataRow.propTypes = {
  style: PropTypes.objectOf(PropTypes.any).isRequired,
  data: PropTypes.shape({
    type: PropTypes.number,
    id: PropTypes.string,
    datetime: PropTypes.string,
    from: PropTypes.string,
    to: PropTypes.string,
    status: PropTypes.string,
    currency: Currency,
    detailHandler: PropTypes.func,
  }).isRequired,
};
