import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';

export function NoMoreDataRow({ style }) {
  return (
    <Grid container style={style}>
      <Grid
        item
        key="nomoredata"
        style={{
          flexBasis: '100%',
          textAlign: 'center',
          color: 'grey',
          fontStyle: 'italic',
        }}
      >
        NO MORE DATA
      </Grid>
    </Grid>
  );
}
NoMoreDataRow.propTypes = {
  style: PropTypes.objectOf(PropTypes.any).isRequired,
};
