import React, { useState, useEffect, createRef } from 'react';
import PropTypes from 'prop-types';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';

import { Paper, Grid } from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import moment from 'moment';
import * as l from 'lodash';
import { parseCurrencies } from '../../utils/utils';
import { ListRow } from './list-row';
import { CELL_TYPE } from '../../constants';
import { Transaction, Currency } from '../types';

const LOADING_ENTRY_NUM = 10;

const getCurrencyData = (currencyId, currencies = []) => {
  const currency = currencies.find((data) => data.currency_id === currencyId);
  return currency;
};

function TransferList({ getCurrenciesResult, dataResult, onScrollToBottom, onDetailClicked }) {
  const [listData, setListData] = useState([]);
  const [previousQueryVar, setPreviousQueryVar] = useState({});
  const listRef = createRef();

  useEffect(() => {
    const safeDataList = dataResult.data ? dataResult.data.transfer.result : [];
    const { offset: prevOffset, ...prevQueryVar } = previousQueryVar;
    const { offset, ...queryVar } = dataResult.variables || {};
    // if data is loaded and there is data present
    // we need to check if it is "new page" or "new query"
    if (getCurrenciesResult.loading || !getCurrenciesResult.data) {
      return;
    }
    if (!dataResult.loading) {
      if (!dataResult.data) {
        // load first data when there is none
        // onDidMount();
        onScrollToBottom({ preventDefault: () => {} }, true);
      } else if (l.isEqual(prevQueryVar, queryVar) && offset !== prevOffset) {
        // same query with different offset means new page
        // concat new result with current result
        setListData(listData.concat(safeDataList));
      } else if (!l.isEqual(prevQueryVar, queryVar)) {
        // different query, use current data only
        setListData(safeDataList);
      }
      // when both condition equal, do nothing
      // set current query variable as previous
      setPreviousQueryVar(dataResult.variables || {});
    } else if (!l.isEqual(prevQueryVar, queryVar)) {
      // when data is loading and it's different query
      // clear list and show loading entries
      setListData([]);
      if (listRef && listRef.current) {
        listRef.current.scrollToItem(0);
      }
    }
  }, [dataResult]);

  // map coin id to coin name first
  let processedList = [];
  const processedRealData = listData.map((e) => {
    const currency = getCurrencyData(
      e.transaction.currency_id,
      parseCurrencies(getCurrenciesResult),
    );
    return {
      type: CELL_TYPE.NORMAL,
      id: e.transfer_id,
      datetime: moment(parseInt(e.data_dt, 10)).format('YYYY/MM/DD HH:mm'),
      from: e.transaction.originator_vasp.vasp_code,
      to: e.transaction.beneficiary_vasp.vasp_code,
      status: e.status,
      currency,
      detailHandler: onDetailClicked,
    };
  });
  if (!dataResult.data || dataResult.data.transfer.nextOffset) {
    // show loading entries when there is more, or first page data not yet arrived
    processedList = [
      ...processedRealData,
      ...new Array(LOADING_ENTRY_NUM).fill({ type: CELL_TYPE.LOADING }),
    ];
  } else {
    processedList = [...processedRealData, { type: CELL_TYPE.END }];
  }

  const handleOnItemRendered = ({ visibleStopIndex }) => {
    if (visibleStopIndex > processedList.length - LOADING_ENTRY_NUM) {
      // user already scroll many, request next page
      onScrollToBottom({ preventDefault: () => {} }, false);
    }
  };

  return (
    <Paper>
      <Grid item container style={{ flexDirection: 'column', height: '90vh' }}>
        <Grid
          container
          item
          style={{
            width: '100%',
            flexBasis: '7%',
            backgroundColor: '#f0f0f0',
            alignItems: 'center',
            paddingRight: '1rem',
          }}
        >
          <Grid item key="dt" style={{ flexBasis: '18%', textAlign: 'center' }}>
            Date
          </Grid>
          <Grid item key="dt" style={{ flexBasis: '18%', textAlign: 'center' }}>
            Transfer ID
          </Grid>
          <Grid item key="from" style={{ flexBasis: '18%', textAlign: 'center' }}>
            From
          </Grid>
          <Grid item key="arrow" style={{ flexBasis: '3%', textAlign: 'center' }}>
            <ArrowForwardIcon />
          </Grid>
          <Grid item key="to" style={{ flexBasis: '18%', textAlign: 'center' }}>
            To
          </Grid>
          <Grid item key="coin" style={{ flexBasis: '10%', textAlign: 'center' }}>
            Currency
          </Grid>
          <Grid item key="sta" style={{ flexBasis: '14%', textAlign: 'center' }}>
            Status
          </Grid>
        </Grid>
        <Grid item style={{ flexBasis: '93%' }} id="list">
          <AutoSizer>
            {({ height, width }) => (
              <List
                ref={listRef}
                height={height}
                width={width}
                itemCount={processedList.length}
                itemSize={60}
                itemData={processedList}
                style={{ backgroundColor: '#f0f0f0' }}
                onItemsRendered={handleOnItemRendered}
              >
                {ListRow}
              </List>
            )}
          </AutoSizer>
        </Grid>
      </Grid>
    </Paper>
  );
}
TransferList.propTypes = {
  getCurrenciesResult: PropTypes.shape({
    loading: PropTypes.bool,
    data: PropTypes.shape({
      getCurrencies: PropTypes.arrayOf(Currency),
    }),
  }).isRequired,
  dataResult: PropTypes.shape({
    loading: PropTypes.bool,
    data: PropTypes.shape({
      transfer: PropTypes.shape({
        nextOffset: PropTypes.number,
        result: PropTypes.arrayOf(
          PropTypes.shape({
            transfer_id: PropTypes.string,
            data_dt: PropTypes.string,
            status: PropTypes.string,
            transaction: Transaction,
          }),
        ),
      }),
    }),
    variables: PropTypes.objectOf(PropTypes.any),
  }).isRequired,
  onScrollToBottom: PropTypes.func.isRequired,
  onDetailClicked: PropTypes.func.isRequired,
};

export default TransferList;
export { LOADING_ENTRY_NUM };
