import React from 'react';
import PropTypes from 'prop-types';
import { getToken, getRoleId } from '../utils/utils';

function RequireRole({ roleIds, children }) {
  const token = getToken();
  const roleId = getRoleId(token);
  if (roleIds.includes(roleId)) {
    return children;
  }
  return <></>;
}

RequireRole.propTypes = {
  roleIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  children: PropTypes.node.isRequired,
};

export default RequireRole;
