import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Grid, Chip, Zoom } from '@material-ui/core';
import { SpeedDial } from '@material-ui/lab';

import { FilterList as FilterIcon } from '@material-ui/icons';
import moment from 'moment';
import { parseCurrencies } from '../../utils/utils';
import { Currency } from '../types';

const getCurrencyData = (currencyId, currencies = []) => {
  const currency = currencies.find((data) => data.currency_id === currencyId);
  return `${currency.currency_name} (${currency.currency_symbol})`;
};

function DisplayElement({ val, key, label }) {
  return (
    <Grid item key={key}>
      <Chip
        label={
          <span>
            <strong>{label} </strong>
            {val}
          </span>
        }
        style={{ textOverflow: 'ellipsis', overflow: 'hidden', maxWidth: '250px' }}
      />
    </Grid>
  );
}
DisplayElement.propTypes = {
  val: PropTypes.string.isRequired,
  key: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

function FilterDisplayer({ onOpenFilterClicked, filterState, getCurrenciesResult }) {
  const [open, setOpen] = useState(false);

  // state to elements
  const elements = [];
  Object.entries(filterState).forEach(([key, value]) => {
    if (key === 'origVaspCode' && value) {
      elements.push(<DisplayElement key="origVaspFilter" val={value} label="From VASP" />);
    }
    if (key === 'beneVaspCode' && value) {
      elements.push(<DisplayElement key="beneVaspFilter" val={value} label="To VASP" />);
    }
    if (key === 'origAddrs' && value.size > 0) {
      value.forEach((a) => {
        elements.push(
          <DisplayElement key={`fromAddressFilter${a}`} val={a} label="From address" />,
        );
      });
    }
    if (key === 'transferIDs' && value.size > 0) {
      value.forEach((a) => {
        elements.push(<DisplayElement key={`fromAddressFilter${a}`} val={a} label="Transfer ID" />);
      });
    }
    if (key === 'beneAddrs' && value.size > 0) {
      value.forEach((a) => {
        elements.push(<DisplayElement key={`toAddressFilter${a}`} val={a} label="To address" />);
      });
    }
    if (key === 'selectedFromDate' && value) {
      elements.push(
        <DisplayElement
          key="fromDateFilter"
          val={moment(value).format('YYYY/MM/DD HH:mm')}
          label="From date"
        />,
      );
    }
    if (key === 'selectedToDate' && value) {
      elements.push(
        <DisplayElement
          key="toDateFilter"
          val={moment(value).format('YYYY/MM/DD HH:mm')}
          label="To date"
        />,
      );
    }
    if (key === 'currency_id' && value) {
      elements.push(
        <DisplayElement
          key="currencyFilter"
          val={getCurrencyData(value, parseCurrencies(getCurrenciesResult))}
          label="Currency"
        />,
      );
    }
    if (key === 'status' && value.size > 0) {
      value.forEach((a) => {
        elements.push(<DisplayElement key={`statusFilter${a}`} val={a} label="Status" />);
      });
    }
  });

  return (
    <Grid
      container
      direction="column-reverse"
      spacing={1}
      alignItems="flex-end"
      style={{
        margin: 0,
        top: 'auto',
        right: 20,
        bottom: 20,
        // left: 'auto',
        position: 'fixed',
        width: 'auto',
      }}
    >
      <Grid item>
        <SpeedDial
          ariaLabel="Filter"
          icon={<FilterIcon />}
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          onClick={onOpenFilterClicked}
          open={open}
        />
      </Grid>
      {elements.map((e, i) => (
        <Zoom
          key={i.toString()}
          in={open}
          timeout={60}
          style={{
            transitionDelay: `${i * 10}ms`,
            display: open ? 'flex' : 'none',
          }}
        >
          {e}
        </Zoom>
      ))}
    </Grid>
  );
}

FilterDisplayer.propTypes = {
  onOpenFilterClicked: PropTypes.func.isRequired,
  filterState: PropTypes.objectOf(PropTypes.any).isRequired,
  getCurrenciesResult: PropTypes.shape({
    loading: PropTypes.bool,
    data: PropTypes.shape({
      getCurrencies: PropTypes.arrayOf(Currency),
    }),
  }).isRequired,
};

export default FilterDisplayer;
