import React from 'react';
import PropTypes from 'prop-types';
import { CELL_TYPE } from '../../constants';
import { NormalDataRow } from './normal-data-row';
import { LoadingRow } from './loading-row';
import { NoMoreDataRow } from './no-more-data-row';

const TransferData = PropTypes.shape({
  type: PropTypes.number,
  id: PropTypes.string,
  datetime: PropTypes.string,
  from: PropTypes.string,
  to: PropTypes.string,
  status: PropTypes.string,
  currency: PropTypes.object,
});

export function ListRow({ index, style, data }) {
  const e = data[index];
  const rowStyle = {
    ...style,
    alignItems: 'center',
    backgroundColor: 'white',
    top: style.top + 3,
    height: style.height - 3,
  };

  switch (e.type) {
    case CELL_TYPE.NORMAL: {
      return <NormalDataRow style={rowStyle} data={e} />;
    }
    case CELL_TYPE.LOADING: {
      return <LoadingRow style={rowStyle} index={index} />;
    }
    case CELL_TYPE.END: {
      return <NoMoreDataRow style={rowStyle} />;
    }
    default: {
      return <div />;
    }
  }
}
ListRow.propTypes = {
  index: PropTypes.number.isRequired,
  style: PropTypes.objectOf(PropTypes.any).isRequired,
  data: PropTypes.arrayOf(TransferData).isRequired,
};
