import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Dialog, DialogTitle, DialogContent, Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { Transaction } from '../types';

function LoadingPlaceHolder() {
  return (
    <Grid
      container
      style={{
        width: '100vw',
        height: '100vh',
        flexDirection: 'column',
        padding: '0 1rem 0 1rem',
      }}
    >
      <Grid item style={{ flexBasis: '5%' }}>
        <Skeleton variant="text" width="10%" />
      </Grid>
      {new Array(18).fill(0).map((__, index) => {
        const rndPadding = Math.floor(Math.random() * 3) * 4;
        const rndWidth = Math.floor(Math.random() * 70) + 20;
        return (
          <Grid
            item
            style={{ paddingLeft: `${rndPadding}%`, flexBasis: '5%' }}
            key={index.toString()}
          >
            <Skeleton variant="text" width={`${rndWidth}%`} />
          </Grid>
        );
      })}
    </Grid>
  );
}

const DataRenderer = ({ data }) => {
  const clone = _.cloneDeep(data);
  if (!clone.expire_date) {
    delete clone.expire_date;
  }
  let JsonDom;
  const canUseWindow = typeof window !== 'undefined';
  if (!canUseWindow) {
    JsonDom = <div />;
  } else {
    // eslint-disable-next-line global-require
    const ReactJson = require('react-json-view');
    JsonDom = (
      // eslint-disable-next-line react/jsx-pascal-case
      <ReactJson.default
        src={clone}
        theme="bright:inverted"
        displayDataTypes={false}
        displayObjectSize={false}
        enableClipboard={false}
        collapseStringsAfterLength={100}
        collapsed={2}
      />
    );
  }
  return JsonDom;
};

function DetailDialog({ open, onClose, data }) {
  return (
    <Dialog onClose={onClose} maxWidth="lg" open={open}>
      <DialogTitle id="simple-dialog-title">Transfer Detail</DialogTitle>
      {JSON.stringify(data) === '{}' ? (
        <DialogContent style={{ overflow: 'hidden' }}>
          <LoadingPlaceHolder />
        </DialogContent>
      ) : (
        <DialogContent>
          <DataRenderer data={data} />
        </DialogContent>
      )}
    </Dialog>
  );
}
DetailDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  data: PropTypes.shape({
    transfer_id: PropTypes.string,
    status: PropTypes.string,
    created_at: PropTypes.string,
    updated_at: PropTypes.string,
    transaction: Transaction,
  }).isRequired,
};

export default DetailDialog;
export { DataRenderer, LoadingPlaceHolder };
