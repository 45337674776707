import React from 'react';
import PropTypes from 'prop-types';

import { checkJWTExpired, getToken, navigate } from '../utils/utils';

function AuthenticatedPage({ children }) {
  const token = getToken();
  if (!token || checkJWTExpired(token)) {
    // push back to home if not logged in or token expired
    navigate('/');
    return <div />;
  }
  return children;
}

AuthenticatedPage.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthenticatedPage;
